import React from "react"
import { BannerWrapper, DefaultContentfulBannerWrapperProps, ITrackingProps } from "@vp/banner-wrapper"
import { useTrackingContext } from "../hooks/TrackingContext"
import {
  createOnClickTrackingHandler,
  removeQueryParamsFrom,
} from "../utils/trackingUtils"
import {  TrackingLinkConfig } from "../types"

interface IBannerProps {
  bannerProps: DefaultContentfulBannerWrapperProps[]
  locale: string
  imageLoadingStrategy?: string | undefined
  pageZone: string
}

interface IBannerExtraProps {
  imageOverrideProps: {
    loading: string | undefined
  }
}

export const Banner = (props: IBannerProps) => {
  const { bannerProps, imageLoadingStrategy, locale, pageZone } = props
  const { sourcePagePath } = useTrackingContext()

  function trackingMethod(
    event: React.MouseEvent<Element, MouseEvent>,
    { destinationUrl, linkIdentifier, ctaPosition, openInNewTab }: ITrackingProps
  ) {
    const trackingConfig = buildTrackingPayload({
      destinationUrl: destinationUrl ?? "",
      linkIdentifier,
      ctaPosition,
      sourcePagePath,
      pageZone,
    })
    if (!trackingConfig) {
      return
    }
    const trackingAndNavigation = createOnClickTrackingHandler(trackingConfig, destinationUrl, openInNewTab)
    if (trackingAndNavigation) {
      trackingAndNavigation(event)
    }
  }

  const bannerExtraProps: IBannerExtraProps = {
    imageOverrideProps: {
      loading: imageLoadingStrategy,
    },
  }
  const bannerComponent = bannerProps && (
    <BannerWrapper bannerProps={bannerProps} extraProps={bannerExtraProps} trackingHandler={trackingMethod} locale={locale} />
  )

  return bannerComponent
}

const buildTrackingPayload = ({
  destinationUrl,
  linkIdentifier,
  ctaPosition,
  sourcePagePath,
  pageZone,
}: {
  destinationUrl: string
  linkIdentifier: string
  ctaPosition?: number | string
  sourcePagePath: string
  pageZone: string
}): TrackingLinkConfig => {

  const eventName = "Navigation Clicked"
  const parsedDestinationPagePath = removeQueryParamsFrom(destinationUrl)
  const eventDetail = `${sourcePagePath};${parsedDestinationPagePath};${pageZone};${linkIdentifier}`

  return {
    eventName,
    properties: {
      category: "Home Page",
      label: `Content:${pageZone}`,
      eventDetail,
      navigationDetail:
        ctaPosition && ((typeof ctaPosition === "number" && ctaPosition > 0) || typeof ctaPosition === "string")
          ? `${linkIdentifier}:${ctaPosition}`
          : linkIdentifier,
    },
    linkAttributes: {
      "data-section": `Content:${pageZone}`,
      "data-position": ctaPosition ?? 0,
      "data-translation": linkIdentifier,
    },
  }
}
