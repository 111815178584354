import React, { useContext } from "react"

const TrackingContext = React.createContext()
const useTrackingContext = () => {
  const tracking = useContext(TrackingContext)
  return tracking
}

const TrackingProvider = props => {
  const slug = typeof window !== "undefined" ? window.location.pathname : "/"
  return (
    <TrackingContext.Provider
      value={{
        sourcePagePath: slug,
      }}
    >
      {props.children}
    </TrackingContext.Provider>
  )
}

export { TrackingProvider, useTrackingContext }
