import React from 'react'
import { useSwanStyleKeys, useSwanFontKeys, useUserContextTracker } from '@vp/ubik-context'
import {
  BoundedContent,
  SWAN_STYLE_KEY_MAP,
  SwanFontNameEnum
} from '@vp/swan'
import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'
import { Banner } from './components/Banner'
import { TrackingProvider } from './hooks/TrackingContext'

export const Fragment = (props: any) => {
  useSwanFontKeys([
    SwanFontNameEnum.GraphikRegular,
    SwanFontNameEnum.GraphikMedium,
    SwanFontNameEnum.Tiempos,
    SwanFontNameEnum.TiemposRegular
  ])
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.banner,
  ])

  trackUsedContext(props)

  const debugAvailability = {
    merchandisingCampaign: props.merchandisingCampaign,
    experimentKey: props.experimentKey,
    variationKey: props.variationKey,
    decision: props.decision,
  }
  const search = typeof window !== 'undefined' ? window.location.search : ''

  return (
    <ContentfulContextProvider space={props.contentfulMetadata.spaceId}>
      {!props.usesMap && (
        <>
          <ContentfulLink id={props.contentfulMetadata?.contentful_id} />
          <DebugSection
            contentfulMetadata={props.debugData?.contentfulMetadata}
            availability={debugAvailability}
            search={search}
          />
        </>
      )}
      <TrackingProvider canonicalUrl='https://www.vistaprint.com'>
        <BoundedContent paddingX='0' mb={props.bottomSpaceParam ? props.bottomSpaceParam : 'between-sections'}>
          <Banner pageZone={props.bannerZone} bannerProps={props.bannerData} locale={props.locale} />
        </BoundedContent>
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}

function trackUsedContext (props: any) {
  const tracker = useUserContextTracker()
  if (props.experimentKey) {
    tracker({
      type: 'experiment',
      name: props.experimentKey,
      variation: props.variationKey
    })
  }
  if (props.decision) {
    tracker({
      type: 'treatment',
      name: props.decision
    })
  }
  if (props.merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: props.merchandisingCampaign
    })
  }
}
